import { Pipe, PipeTransform } from '@angular/core';
import { ILocation } from '../models/location/location.model';

@Pipe({
  name: 'locationName',
  standalone: true
})
export class LocationNamePipe implements PipeTransform {

  transform(location: ILocation | null | undefined, isOnline?: boolean): string | null {
    if (isOnline) {
      return 'online';
    } else {
      return location?.title ?? null;
    }
  }

}

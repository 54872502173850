import { Injectable } from '@angular/core';
import { IUser } from '../../models/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserHelperService {

  constructor() { }

  public getFullName(user: Partial<IUser>) {
    return `${user.name} ${user.surname}`;
  }
}
